// src/App.tsx
import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { onAuthStateChanged, User } from "firebase/auth";

// Components
import EventDetail from "./components/EventDetail";
import Week from "./components/Week";
import Today from "./components/Today";
import Landing from "./components/Landing";
import Sidebar from "./components/Sidebar";
import Inbox from "./components/Inbox";

// Firebase
import { db } from "./firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

// Near entry of your product, init Mixpanel
mixpanel.init("72f38a557917069909bcb7fec36a7db5", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [selectedNav, setSelectedNav] = useState("");

  if (user) {
    mixpanel.identify(user.uid);
    mixpanel.people.set({ $name: user.displayName, $email: user.email });
  }

  // Real-time listener for unread notifications
  const listenForUnreadNotifications = (userEmail: string) => {
    const q = query(
      collection(db, "notifications"),
      where("recipientEmail", "==", userEmail),
      where("read", "==", false)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setUnreadNotifications(snapshot.size > 0);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const unsubscribeNotifications = listenForUnreadNotifications(
          user.email!
        );
        setLoading(false);

        return () => {
          unsubscribeNotifications();
        };
      } else {
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  if (loading) {
    return (
      <>
        <div className="flex">
          <Router>
            <Sidebar
              unreadNotifs={unreadNotifications}
              selectedNav={selectedNav}
            />
          </Router>
          <div className="min-h-screen">Loading...</div>
        </div>
      </>
    );
  }

  const MainLayoutWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="p-10 bg-gray-100 min-h-screen">
        <div className="overflow-x-clip">{children}</div>
      </div>
    );
  };

  return (
    <div className="flex">
      <Router>
        {user ? (
          <Sidebar
            unreadNotifs={unreadNotifications}
            selectedNav={selectedNav}
          />
        ) : null}
        <div className={user ? "flex-grow ml-44" : "flex-grow"}>
          <Routes>
            <Route
              path="/this-week"
              element={
                <MainLayoutWrapper>
                  {user ? (
                    <Week week="this-week" key="this-week" />
                  ) : (
                    <Landing />
                  )}
                </MainLayoutWrapper>
              }
            />
            <Route
              path="/today"
              element={
                <MainLayoutWrapper>
                  {user ? <Today /> : <Landing />}
                </MainLayoutWrapper>
              }
            />
            <Route path="/inbox" element={user ? <Inbox /> : <Landing />} />
            <Route
              path="/next-week"
              element={
                <MainLayoutWrapper>
                  {user ? (
                    <Week week="next-week" key="next-week" />
                  ) : (
                    <Landing />
                  )}
                </MainLayoutWrapper>
              }
            />
            <Route
              path="/e/:eventId"
              element={
                user ? (
                  <EventDetail setSelectedNav={setSelectedNav} />
                ) : (
                  <Navigate to="/signin" />
                )
              }
            />
            <Route path="/signin" element={<Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/this-week" />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
