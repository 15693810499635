import React from "react";

interface EventListFiltersProps {
  filters: {
    declined: boolean;
    cancelled: boolean;
    invites: boolean;
    pastDays: boolean;
  };
  setFilters: Function;
  showPastDaysOption: boolean;
}

const EventListFilters: React.FC<EventListFiltersProps> = ({
  filters,
  setFilters,
  showPastDaysOption,
}) => {
  const handleInputChange = (key: keyof typeof filters) => {
    setFilters({
      ...filters,
      [key]: !filters[key],
    });
  };

  return (
    <div className="flex justify-between items-center text-gray-500 text-sm">
      <div>
        <span className="mr-2">Show:</span>
        <label className="mr-3">
          <input
            type="checkbox"
            className="mr-1"
            checked={filters.invites}
            onChange={() => handleInputChange("invites")}
          />
          Invites
        </label>
        <label className="mr-3">
          <input
            type="checkbox"
            className="mr-1"
            checked={filters.declined}
            onChange={() => handleInputChange("declined")}
          />
          Declined
        </label>
        <label className="mr-3">
          <input
            type="checkbox"
            className="mr-1"
            checked={filters.cancelled}
            onChange={() => handleInputChange("cancelled")}
          />
          Cancelled
        </label>

        {showPastDaysOption && (
          <label className="mr-3">
            <input
              type="checkbox"
              className="mr-1"
              checked={filters.pastDays}
              onChange={() => handleInputChange("pastDays")}
            />
            Past days
          </label>
        )}
      </div>
    </div>
  );
};

export default EventListFilters;
