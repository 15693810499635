import React from "react";

import { formatDistanceToNow } from "date-fns";
import { displayPhoto } from "../utils";

import Linkify from "react-linkify";

interface CommentProps {
  comment: {
    id: string;
    commentor: string;
    commentorPhotoURL: string;
    date: Date;
    comment: string;
  };
}

const Comment: React.FC<CommentProps> = ({ comment }) => {
  return (
    <div
      className="bg-white p-3 mt-4 rounded-lg block-inline shadow-sm border border-gray-150"
      key={comment.id}
    >
      <img
        src={displayPhoto(comment.commentorPhotoURL)}
        alt="commentor"
        className="w-6 h-6 rounded-full float-left mr-[7px] ml-[2px]"
      />
      <div className="flex items-center mb-1">
        <span className="text-gray-600 mr-2 font-semibold">
          {comment.commentor}
        </span>
        <span className="text-gray-400 text-sm">
          {formatDistanceToNow(comment.date, { addSuffix: true })}
        </span>
      </div>
      <p className="whitespace-pre-line mt-[7px]">
        <Linkify>{comment.comment}</Linkify>
      </p>
    </div>
  );
};

export default Comment;
