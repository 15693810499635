import React from "react";

import { useRef } from "react";

import { Bars3Icon } from "@heroicons/react/24/outline";

import { ControlledInput } from "./ControlledInput";

interface CheckboxProps {
  event: any;
  item?: any;
  addItem: Function;
  saveChecklist: Function;
  arrayField: string;
  type: string;
  selectNextItem: Function;
  saveChecklistTimeoutRef?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  event,
  item: initialItem,
  type,
  arrayField,
  addItem,
  saveChecklist,
  selectNextItem,
  saveChecklistTimeoutRef,
}) => {
  const [item, setItem] = React.useState(initialItem);
  const liRef = useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    setItem(initialItem);
  }, [initialItem]);

  // Real-time updating of the item as the user types
  const editItem = async (itemId: string, newTitle: string) => {
    const updatedItems: any = event[arrayField].map((item: any) => {
      if (item.id === itemId) {
        return { ...item, title: newTitle };
      }
      return item;
    });

    await saveChecklist(updatedItems, arrayField);
  };

  const deleteItem = async (itemId: string, e: any) => {
    selectNextItem(itemId);

    const updatedItems = event[arrayField].filter(
      (item: any) => item.id !== itemId
    );
    await saveChecklist(updatedItems, arrayField);

    e.preventDefault();
  };

  // Check or uncheck an agenda item
  const toggleItem = async (itemId: string) => {
    const updatedItems = event[arrayField].map((item: any) => {
      if (item.id === itemId) {
        return { ...item, completed: !item.completed };
      }
      return item;
    });

    await saveChecklist(updatedItems, arrayField);
  };

  const handleDragStart = (e: any) => {
    e.dataTransfer.setData("text/plain", item.id);
    e.dataTransfer.effectAllowed = "move";

    // Lower opacity while dragging
    e.currentTarget.style.opacity = "0.3";
  };

  const handleDragEnd = (e: any) => {
    e.currentTarget.style.opacity = "1";
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = async (e: any) => {
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const draggedItemId = item.id; // This is the ID of the item being dragged

    const items = [...event[arrayField]];
    const droppedIndex = items.findIndex((item) => item.id === droppedItemId);
    const draggedIndex = items.findIndex((item) => item.id === draggedItemId);

    if (droppedIndex !== -1 && draggedIndex !== -1) {
      const [reorderedItem] = items.splice(droppedIndex, 1);
      items.splice(draggedIndex, 0, reorderedItem);

      await saveChecklist(items, arrayField);
    }
  };

  if (!item) {
    return null;
  }

  return (
    <li
      key={item?.id}
      ref={liRef}
      className="p-1 pl-5 bg-gray-100 rounded border-0"
      draggable="true"
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="flex items-center group/item relative">
        <Bars3Icon
          className="w-3 h-3 mr-2 text-gray-400 cursor-move absolute -left-4
                       opacity-0 group-hover/item:opacity-100 
                       hover:opacity-100 transition-opacity duration-200"
        />

        <input
          type="checkbox"
          className="mr-2"
          onChange={() => toggleItem(item.id)}
          checked={item.completed}
        />
        <ControlledInput
          type="text"
          id={item.id}
          className="bg-gray-100 text-black rounded focus:outline-none w-full"
          onChange={(e) => {
            editItem(item.id, e.currentTarget.value);
          }}
          placeholder="Add an item..."
          value={item.title}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !e.currentTarget.value) {
              deleteItem(item.id, e);
            } else if (e.key === "Enter") {
              addItem(type);
              return false;
            }
          }}
        />
      </div>
    </li>
  );
};

export default Checkbox;
