import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";

import { auth } from "../firebase";

/*
 * Function to send an email to all attendees who have RSVP'd yes or maybe
 * when a comment is made on an event
 */
const sendCommentEmail = async (
  comment: string,
  attendees: any,
  eventTitle: string,
  eventID: string
) => {
  if (
    !auth.currentUser ||
    !auth.currentUser.displayName ||
    !eventTitle ||
    !eventID ||
    !comment ||
    attendees.length === 0
  )
    return;

  // Don't email ourselves
  let recipients = attendees.filter(
    (attendee: any) => attendee.email !== auth.currentUser?.email
  );

  // If it's a big meeting, only email people who've responded
  if (recipients.length >= 6) {
    recipients = recipients.filter(
      (attendee: any) =>
        attendee.responseStatus === "accepted" ||
        attendee.responseStatus === "tentative"
    );
  }

  recipients = recipients.map((attendee: any) => attendee.email);

  const emailMessagePlain = `${auth.currentUser?.displayName} said: \n\n ${comment} \n\n
  
  View the event: https://paceful.ai/e/${eventID}`;

  const emailMessageHTML = `<em>${auth.currentUser?.displayName} said:</em>
  
  <p>${comment ? comment.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""}</p>
  
  <a href="https://paceful.ai/e/${eventID}">View the event on Paceful</a>`;

  // Insert a doc into the mail collection to send an email to all attendees who have RSVP'd yes, using addDoc
  const emailRef = await addDoc(collection(db, "mail"), {
    message: {
      text: emailMessagePlain,
      html: emailMessageHTML,
      subject: `New comment on ${eventTitle}`,
    },
    to: recipients,
  });

  console.log("created email", emailRef.id, recipients);
};

export { sendCommentEmail };
