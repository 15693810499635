import {
  formatDistanceToNowStrict,
  formatDistanceStrict,
  parseISO,
} from "date-fns";

function formatShortDistanceToNow(date: Date | number | string): string {
  const distanceString = formatDistanceToNowStrict(
    typeof date === "string" ? parseISO(date) : date,
    { addSuffix: false }
  );

  const [amount, unit] = distanceString.split(" ");

  switch (unit) {
    case "second":
    case "seconds":
      return amount + "s";
    case "minute":
    case "minutes":
      return amount + "m";
    case "hour":
    case "hours":
      return amount + "h";
    case "day":
    case "days":
      return amount + "d";
    case "month":
    case "months":
      return amount + "mo";
    case "year":
    case "years":
      return amount + "y";
    default:
      return distanceString;
  }
}

function formatShortDistance(
  date1: Date | number | string,
  date2: Date | number | string
): string {
  const distanceString = formatDistanceStrict(
    typeof date1 === "string" ? parseISO(date1) : date1,
    typeof date2 === "string" ? parseISO(date2) : date2,
    { addSuffix: false }
  );

  const [amount, unit] = distanceString.split(" ");

  if (amount === "1" && unit === "day") {
    return "All day";
  }

  switch (unit) {
    case "seconds":
      return amount + "s";
    case "minute":
    case "minutes":
      return amount + "m";
    case "hour":
    case "hours":
      return amount + "h";
    case "day":
    case "days":
      return amount + "d";
    case "month":
    case "months":
      return amount + "mo";
    case "year":
    case "years":
      return amount + "y";
    default:
      return distanceString;
  }
}

/*
 * A function that returns either "This Week" or "Next Week", depending on the
 * date passed in. Returns as string "this-week" or "next-week" to match URLs.
 *
 * Counting weeks as starting on Sunday.
 *
 */
function whichWeek(date: Date, returnSlug?: boolean): string {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const weekStart = new Date(now);
  weekStart.setDate(now.getDate() - now.getDay());

  const nextWeekStart = new Date(weekStart);
  nextWeekStart.setDate(weekStart.getDate() + 7);

  if (date >= weekStart && date < nextWeekStart) {
    return returnSlug ? "this-week" : "This Week";
  }

  if (
    date.getTime() >= nextWeekStart.getTime() &&
    date.getTime() <
      new Date(nextWeekStart).setDate(nextWeekStart.getDate() + 7)
  ) {
    return returnSlug ? "next-week" : "Next Week";
  }

  return "";
}

/*
 * A function to return the right format for a photo
 *
 * Google's APIs sometimes return the full URL of a photo, in which case we use that straight up.
 * Other times, Google gives us a base64-encoded string, so we need to render it.
 */
function displayPhoto(photo: string): string {
  if (!photo) {
    return "/images/default-user.png";
  }

  // Check if the string is a URL (Google hosted)
  if (photo.startsWith("http")) {
    return photo;
  }

  // Otherwise it's a base64-encoded string, render it!
  return `data:image/jpeg;base64,${photo}`;
}

export {
  formatShortDistanceToNow,
  formatShortDistance,
  whichWeek,
  displayPhoto,
};
