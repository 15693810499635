import React, { Fragment } from "react";

import { format, isBefore, addMinutes } from "date-fns";

import { formatShortDistance, displayPhoto } from "../utils";

import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import EventProps from "../interfaces/EventProps";
import { auth } from "../firebase";

const EventCard: React.FC<{ event: EventProps }> = ({ event }) => {
  const navigate = useNavigate();
  if (!event) return null;

  // Figure out if the current user has not RSVPd to the event
  const user = auth.currentUser;
  if (!user) return null;
  const userAttendee = event.attendees.find(
    (attendee) => attendee.email === user.email
  );
  const eventInvite =
    userAttendee && userAttendee.responseStatus === "needsAction";
  const eventPassed = isBefore(addMinutes(event.end.toDate(), 10), new Date());
  const eventCancelled = event.status === "cancelled";
  const eventDeclined = userAttendee?.responseStatus === "declined";

  // Format display of event duration (e.g. 1h, 30m, 2d, or "All Day")
  const duration = formatShortDistance(
    event.end.toDate(),
    event.start.toDate()
  );
  const isDaysOrMore = /^\d+d$/.test(duration) && parseInt(duration) >= 2;

  return (
    <li
      key={event.id}
      className={`transition-shadow duration-100 rounded-lg cursor-pointer w-[310px] ${
        eventPassed
          ? `bg-gray-50 border-2 border-gray-200 p-3 ${
              eventInvite ? "border-gray-250" : ""
            }`
          : eventInvite
            ? "bg-gray-50 border border-dashed border-purple-400 p-4 shadow-sm hover:shadow-md hover:bg-white"
            : "bg-white shadow-md hover:shadow-lg p-4"
      }`}
      onClick={() => navigate(`/e/${event.id}`)}
    >
      <div className="truncate">
        {eventPassed && !eventCancelled && !eventInvite && (
          <CheckCircleIcon className="w-6 h-6 text-green-500 inline mr-1"></CheckCircleIcon>
        )}
        {eventInvite && eventPassed && (
          <MinusCircleIcon className="w-6 h-6 text-green-500 inline mr-1" />
        )}
        {eventCancelled && (
          <XCircleIcon className="w-6 h-6 text-red-500 mr-1 inline align-top"></XCircleIcon>
        )}
        <h2
          className={`inline text-black ${
            eventPassed ? "text-sm font-medium" : "text-md mb-1 font-semibold"
          }`}
        >
          {event.title}
        </h2>
      </div>
      {!eventPassed ? (
        <div className="flex items-center">
          <p className="text-gray-500">
            {duration !== "All day" && !isDaysOrMore && (
              <>{format(event.start.toDate(), "h:mmaaa")} • </>
            )}
            {duration}
          </p>
          {event.comments?.length > 0 && (
            <div className="flex items-center ml-auto">
              <ChatBubbleLeftIcon className="w-4 h-4 mr-1" />
              <span className="text-gray-500">{event.comments.length}</span>
            </div>
          )}
          <div className="flex items-center ml-auto text-gray-500 text-sm">
            {event.attendeeData.map((attendee: any, index: number) => {
              if (attendee.email === user.email && eventInvite) return null;
              if (index === 5) {
                return "+" + (event.attendeeData.length - 5);
              } else if (index < 5) {
                return (
                  <Fragment key={attendee.email}>
                    {attendee.email === user.email && eventDeclined && (
                      <div className="relative">
                        <XMarkIcon className="w-5 h-5 text-red-500 inline -mr-1" />
                      </div>
                    )}
                    <img
                      src={displayPhoto(attendee.photo)}
                      alt={attendee.email}
                      className={`w-6 h-6 rounded-full border-2 border-white ${
                        index !== 0 ? "-ml-2" : ""
                      }`}
                    />
                  </Fragment>
                );
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </li>
  );
};

export default EventCard;
