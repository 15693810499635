import { query } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { auth, db } from "../firebase";
import {
  onSnapshot,
  collection,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { formatShortDistanceToNow, displayPhoto } from "../utils";
import { Timestamp } from "firebase/firestore";
import EventDetail from "./EventDetail";
import {
  CalendarIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/20/solid";
import mixpanel from "mixpanel-browser";

interface NotificationProps {
  id: string;
  eventTitle: string;
  eventId: string;
  date: Timestamp;
  type: string;
  content: string;
  senderName: string;
  senderProfilePhoto: string;
  read: boolean;
}

const Inbox: React.FC = () => {
  const [notifications, setNotifications] = React.useState<NotificationProps[]>(
    []
  );
  const [loading, setLoading] = React.useState(true);
  const [selectedEvent, setSelectedEvent] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track("Opened Inbox", {});
  }, []);

  useEffect(() => {
    mixpanel.track("Opened Notification", {});
  }, [selectedEvent]);

  // Make sure we're logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const unsubscribeSnapshot = onSnapshot(
          query(
            collection(db, "notifications"),
            where("recipientEmail", "==", user.email!),
            orderBy("date", "desc"),
            limit(50)
          ),
          (snapshot) => {
            const notifications = snapshot.docs.map((doc) => ({
              id: doc.id,
              eventTitle: doc.data().eventTitle,
              eventId: doc.data().eventId,
              date: doc.data().date,
              type: doc.data().type,
              content: doc.data().content,
              senderName: doc.data().senderName,
              senderProfilePhoto: doc.data().senderProfilePhoto,
              read: doc.data().read,
            }));

            const existingIds = new Set(notifications.map((n) => n.eventId));

            const updatedNotifications = notifications
              // Remove any notifications about the same event, to simulate grouping
              // as firestore doesn't support it natively
              .filter(
                (notification, index, self) =>
                  !existingIds.has(notification.eventId) ||
                  self.findIndex((n) => n.eventId === notification.eventId) ===
                    index
              )
              // Prepare the content
              .map((notification) => {
                if (notification.type === "comment") {
                  let content =
                    notification.content.length > 30
                      ? notification.content.substring(0, 42) + "..."
                      : notification.content;
                  notification.content =
                    notification.senderName + ": " + content;
                } else if (notification.type === "invite") {
                  notification.content =
                    (notification.senderName.length > 30
                      ? notification.senderName.substring(0, 30) + "..."
                      : notification.senderName) + " invited you";
                }

                return notification;
              });

            setNotifications(updatedNotifications);
            if (loading) setLoading(false);
          }
        );

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        navigate("/signin");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    // Set the first event as selected by default
    if (!loading && notifications.length > 0 && !selectedEvent) {
      setSelectedEvent(notifications[0].eventId);
    }
  }, [loading]);

  if (loading) {
    return <div className="p-10">Loading...</div>;
  }

  return (
    <div className="flex h-screen">
      <div className="w-1/3 bg-white overflow-hidden border-r border-gray-200 flex flex-col">
        <div className="flex-1 overflow-y-auto">
          <h2 className="text-lg font-semibold p-7 mt-9">Inbox</h2>
          <ul className="border-t border-gray-200">
            {notifications.map((notification) => {
              let notificationTypeIcon;
              let notificationIconColor;
              if (notification.type === "comment") {
                notificationTypeIcon = (
                  <ChatBubbleOvalLeftIcon className="absolute top-[3px] left-[4px] w-4 h-4 text-black"></ChatBubbleOvalLeftIcon>
                );
                notificationIconColor = "bg-blue-200";
              } else {
                notificationTypeIcon = (
                  <CalendarIcon className="absolute top-[3px] left-[4px] w-4 h-4 text-black"></CalendarIcon>
                );
                notificationIconColor = "bg-amber-200";
              }

              return (
                <li
                  className={
                    `border-b border-gray-200 hover:bg-gray-100 cursor-pointer ` +
                    (selectedEvent === notification.eventId
                      ? "bg-gray-100"
                      : "")
                  }
                  onClick={() => {
                    setSelectedEvent(notification.eventId);
                  }}
                  key={notification.id}
                >
                  <div className="pl-7 pr-3 py-4">
                    <div className="relative">
                      {!notification.read && (
                        <div className="absolute -left-4 top-6 rounded-full h-2 w-2 bg-purple-500"></div>
                      )}
                      <div className="flex">
                        <div className="flex-shrink-0 w-12 mr-4 relative">
                          <div
                            className={
                              `rounded-full absolute top-7 left-7 w-6 h-6 ` +
                              notificationIconColor
                            }
                          >
                            {notificationTypeIcon}
                          </div>
                          <img
                            src={displayPhoto(notification.senderProfilePhoto)}
                            alt={notification.senderName}
                            className="w-12 h-12 rounded-full"
                          />
                        </div>
                        <div className="flex-grow min-w-0 mt-[5px]">
                          <p className="text-sm text-gray-400 float-right mr-1">
                            {formatShortDistanceToNow(
                              notification.date.toDate()
                            )}
                          </p>
                          <h3 className="text-sm font-semibold truncate max-w-[calc(100%-1rem)]">
                            {notification.eventTitle}
                          </h3>
                          <p className="text-sm text-gray-500">
                            {notification.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="w-4/5 overflow-hidden flex flex-col">
        <div className="flex-1 overflow-y-auto">
          <EventDetail suppliedEventId={selectedEvent} key={selectedEvent} />
        </div>
      </div>
    </div>
  );
};

export default Inbox;
