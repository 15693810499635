// src/components/Welcome.tsx
import React from "react";
import { signIn } from "../firebase";

const Welcome: React.FC = () => {
  const handleSignIn = async () => {
    try {
      await signIn();
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-2">Welcome to Paceful</h1>
      <p className="mb-8">Fewer meetings, but better</p>
      <button
        onClick={handleSignIn}
        className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
      >
        Sign in with Google
      </button>
    </div>
  );
};

export default Welcome;
