import React from "react";

import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

import Checklist from "./Checklist";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

interface ChecklistWrapperProps {
  event: any;
  setEvent: Function;
  type: string;
  saveChecklistTimeoutRef?: any;
}

const ChecklistWrapper: React.FC<ChecklistWrapperProps> = ({
  event,
  setEvent,
  type,
  saveChecklistTimeoutRef,
}: any) => {
  const eventId = event?.id;
  const arrayField = type === "agenda" ? "agendaItems" : "tasks";

  const saveChecklist = (updatedItems: any, arrayField: string) => {
    if (!eventId) return;

    // Clear the timer
    if (saveChecklistTimeoutRef.current) {
      clearTimeout(saveChecklistTimeoutRef.current);
    }

    // Save to db after 2s of inactivity
    saveChecklistTimeoutRef.current = setTimeout(() => {
      const eventDoc = doc(db, "events", eventId);

      updateDoc(eventDoc, {
        [`${arrayField}`]: updatedItems,
      });
    }, 2000);

    // Update the event state locally right away
    return setEvent((prevState: any) => {
      if (prevState) {
        return {
          ...prevState,
          [`${arrayField}`]: updatedItems,
        };
      }
      return prevState;
    });
  };

  // Add an item to the event
  const addItem = async (type: string) => {
    if (!event || !eventId) return;

    const newItem = {
      title: "",
      owner: "",
      description: "",
      completed: false,
      id: Math.random().toString(36).slice(2, 9),
    };

    const updatedItems: any = event[arrayField]
      ? event[arrayField].concat(newItem)
      : [newItem];

    await saveChecklist(updatedItems, arrayField);

    // Focus on the newly created element
    const newItemElement = document.getElementById(newItem.id);
    if (newItemElement) {
      newItemElement.focus();
    }
  };

  return (
    <>
      <div>
        <h2 className="font-semibold inline text-gray-800">
          {type === "agenda" ? "Agenda" : "Action items"}
        </h2>
        <PlusCircleIcon
          className="w-5 h-5 ml-1 text-blue-500 inline align-middle cursor-pointer"
          onClick={() => addItem(type)}
        />
      </div>
      <div>
        <Checklist
          saveChecklist={saveChecklist}
          event={event}
          arrayField={arrayField}
          addItem={addItem}
          saveChecklistTimeoutRef={saveChecklistTimeoutRef}
          type={type}
        />
      </div>
    </>
  );
};

export default ChecklistWrapper;
