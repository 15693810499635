import React from "react";

import Checkbox from "./Checkbox";

interface ChecklistProps {
  event: any;
  addItem: Function;
  saveChecklist: Function;
  type: string;
  saveChecklistTimeoutRef?: any;
  arrayField: string;
}

const Checklist: React.FC<ChecklistProps> = ({
  event,
  addItem,
  type,
  saveChecklist,
  saveChecklistTimeoutRef,
  arrayField,
}) => {
  // Handle focus when a user deletes an item
  const selectNextItem = async (itemId: string) => {
    // Find the index of the item that will be deleted
    const index = event[arrayField].findIndex(
      (item: any) => item.id === itemId
    );
    const nextItem = event[arrayField][index - 1]
      ? event[arrayField][index - 1]
      : event[arrayField][index + 1];

    if (nextItem) {
      const nextItemId = nextItem.id;
      const nextItemInput = document.getElementById(nextItemId);
      if (nextItemInput) {
        nextItemInput.focus();
      }
    }
  };

  return (
    <ul className="mt-2 -ml-5">
      {event[arrayField].map((item: any) => (
        <Checkbox
          key={item.id}
          event={event}
          item={item}
          type={type}
          saveChecklistTimeoutRef={saveChecklistTimeoutRef}
          addItem={addItem}
          saveChecklist={saveChecklist}
          arrayField={arrayField}
          selectNextItem={selectNextItem}
        />
      ))}
    </ul>
  );
};

export default Checklist;
