import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Timestamp,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { format, differenceInMinutes } from "date-fns";

import { displayPhoto, formatShortDistance, whichWeek } from "../utils";

import { auth } from "../firebase";

import { sendCommentEmail } from "../utils/Emails";

import axios from "axios";
import { XCircleIcon, CheckIcon } from "@heroicons/react/24/outline";

import ChecklistWrapper from "./ChecklistWrapper";
import mixpanel from "mixpanel-browser";

import Comment from "./Comment";

import Linkify from "react-linkify";
import TextareaAutosize from "react-textarea-autosize";

interface EventDetailProps {
  id: string;
  title: string;
  description: string;
  location: string;
  status: string;
  onlineLocation: string;
  agendaItems: {
    id: string;
    title: string;
    owner: string;
    description: string;
    completed: boolean;
  }[];
  start: Timestamp;
  end: Timestamp;
  comments: {
    id: string;
    commentor: string;
    commentorPhotoURL: string;
    comment: string;
    date: Date;
  }[];
  attendees: {
    email: string;
    organizer: boolean;
    responseStatus: string;
    photo: string;
    load: string;
  }[];
  attendeeData: {
    photo: string;
    load: string;
    email: string;
  }[];
  tasks: {
    id: string;
    title: string;
    owner: string;
    description: string;
    completed: boolean;
  }[];
  googleCalendarLink?: string;
}

interface EventDetailViewProps {
  suppliedEventId?: string;
  setSelectedNav?: Function;
}

const EventDetail: React.FC<EventDetailViewProps> = ({
  // When we display an event in the Inbox, it sends the eventId as a prop
  suppliedEventId,
  // A function to set the selectedNav state in the Sidebar
  setSelectedNav,
}) => {
  // Check if the eventId is supplied as a prop, otherwise get it from the URL
  const { eventId: eventIdInURL } = useParams<{ eventId: string }>();
  const eventId = suppliedEventId || eventIdInURL;

  const [event, setEvent] = useState<EventDetailProps | null>(null);
  const [newComment, setNewComment] = useState("");
  const [eventCancelled, setEventCancelled] = useState(false);
  const calendarTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [userHasRSVPd, setUserHasRSVPd] = useState(false);
  const saveChecklistTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  // Clean up
  useEffect(() => {
    return () => {
      if (calendarTimeoutRef.current) {
        clearTimeout(calendarTimeoutRef.current);
      }

      setEventCancelled(false);
      setSelectedNav && setSelectedNav("");
    };
  }, [eventId]);

  useEffect(() => {
    if (!eventId) return;
    // Check that we're not on the inbox
    if (eventIdInURL) {
      mixpanel.track("Event Detail Viewed", {
        eventId: eventIdInURL,
      });
    }
  }, [eventIdInURL, eventId]);

  useEffect(() => {
    if (eventId) {
      const eventDoc = doc(db, "events", eventId);

      const unsubscribe = onSnapshot(eventDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setEvent({
            id: docSnapshot.id,
            title: data?.title || "",
            description: data?.description || "",
            location: data?.location || "",
            onlineLocation: data?.onlineLocation || "",
            start: data?.start || "",
            end: data?.end || "",
            comments: data?.comments || [],
            attendees: data?.attendees || [],
            attendeeData: data?.attendeeData || [],
            googleCalendarLink: data?.googleCalendarLink || "",
            tasks: data?.tasks || [
              {
                title: "",
                owner: "",
                description: "",
                completed: false,
                id: Math.random().toString(36).slice(2, 9),
              },
            ],
            agendaItems: data?.agendaItems || [
              {
                title: "",
                owner: "",
                description: "",
                completed: false,
                id: Math.random().toString(36).slice(2, 9),
              },
            ],
            status: data?.status || "",
          });

          // Merge attendeeData onto attendees
          const attendees = data?.attendees || [];
          attendees.forEach((attendee: any) => {
            const attendeeData = data?.attendeeData.find(
              (ad: any) => ad.email === attendee.email
            );
            if (attendeeData) {
              attendee.photo = attendeeData.photo;
              attendee.load = attendeeData.load;
            }
          });

          // Loop through comments and reformat their date to string
          const comments = data?.comments || [];
          comments.forEach((comment: any) => {
            comment.date = comment.date.toDate().toString();
          });

          // Reverse order of comments
          comments.reverse();

          if (data?.status === "cancelled") {
            setEventCancelled(true);
          }

          // Mark all notifications as seen for this event and user
          // because we've opened it
          const markNotificationsAsSeen = async () => {
            const q = query(
              collection(db, "notifications"),
              where("eventId", "==", eventId),
              where("recipientEmail", "==", auth.currentUser?.email)
            );
            try {
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((notification: any) => {
                updateDoc(doc(db, "notifications", notification.id), {
                  read: true,
                });
              });
            } catch (error) {
              console.error("Error marking notifications as seen: ", error);
            }
          };

          markNotificationsAsSeen();

          setSelectedNav &&
            setSelectedNav(whichWeek(data?.start.toDate(), true));

          // Check if the current user has RSVP'd
          const currentUser = auth.currentUser;
          if (currentUser) {
            const userHasRSVPd = data?.attendees.some(
              (attendee: any) =>
                attendee.email === currentUser.email &&
                attendee.responseStatus !== "needsAction"
            );
            setUserHasRSVPd(userHasRSVPd);
          }
        }
      });
      return unsubscribe;
    }
  }, [eventId]);

  // A new function to set the event's date to today, and turn it into a full-day event, thereby effectively removing it from the calendar
  const handleResolveEarly = async () => {
    if (!event || !eventId) return;
    const eventDoc = doc(db, "events", eventId);

    try {
      const updatedEvent = {
        ...event,
        start: Timestamp.now(),
        end: Timestamp.now(),
      };
      setEvent(updatedEvent);

      updateDoc(eventDoc, {
        start: Timestamp.now(),
        end: Timestamp.now(),
      });

      mixpanel.track("Marked as Done", {
        eventId: eventId,
      });

      await axios.put(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
        {
          start: {
            date: format(new Date(), "yyyy-mm-dd"),
            timeZone: "America/Los_Angeles",
          },
          end: {
            date: format(new Date(), "yyyy-mm-dd"),
            timeZone: "America/Los_Angeles",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating event start time", error);
    }
  };

  // Update the RSVP status of the current user in the attendee list
  const handleRSVP = async (status: string) => {
    if (!eventId) return;

    // Re-fetch the event to make sure we have the latest version
    const eventDoc = doc(db, "events", eventId);
    const eventSnapshot = await getDoc(eventDoc);
    const currentEvent = eventSnapshot.data();

    const newAttendees = currentEvent?.attendees.map((attendee: any) => {
      if (attendee.email === auth.currentUser?.email) {
        return {
          ...attendee,
          responseStatus: status,
        };
      }
      return attendee;
    });

    // Merge attendeeData onto attendees
    // TODO: Refactor so that attendeeData is always part of attendee?
    newAttendees.forEach((attendee: any) => {
      const attendeeData = currentEvent?.attendeeData.find(
        (ad: any) => ad.email === attendee.email
      );
      if (attendeeData) {
        attendee.photo = attendeeData.photo;
        attendee.load = attendeeData.load;
      }
    });

    const user = auth.currentUser;
    if (user) {
      // TODO: Save the return value of this function and use it to update the local state + DB
      // because right now if you refresh the page there'll be a time delay before you see the new status
      saveCalendarEventToGoogle(eventId, "attendees", newAttendees);
      setUserHasRSVPd(true);

      // Update the local state
      setEvent((prevState: any) => {
        if (prevState) {
          return {
            ...prevState,
            attendees: newAttendees,
          };
        }
        return prevState;
      });
    }
  };

  const handleAddComment = async () => {
    if (eventId && newComment) {
      const eventDoc = doc(db, "events", eventId);
      const newCommentData = {
        id: Math.random().toString(36).substr(2, 9),
        commentor: auth.currentUser?.displayName,
        commentorPhotoURL: auth.currentUser?.photoURL,
        comment: newComment,
        date: new Date(),
      };
      updateDoc(eventDoc, {
        comments: arrayUnion(newCommentData),
      });

      mixpanel.track("Added Comment", {
        eventId: eventId,
      });

      // Clear the input field
      setNewComment("");

      // Update the event state locally
      setEvent((prevState: any) => {
        if (prevState) {
          return {
            ...prevState,
            comments: [...prevState.comments, newCommentData],
          };
        }
        return prevState;
      });

      event?.attendees.forEach((attendee) => {
        if (attendee.email === auth.currentUser?.email) return;

        // Add a new entry to the notifications collection
        addDoc(collection(db, "notifications"), {
          type: "comment",
          eventId: eventId,
          eventTitle: event?.title,
          recipientEmail: attendee.email,
          senderName: auth.currentUser?.displayName,
          senderID: auth.currentUser?.uid,
          senderProfilePhoto: auth.currentUser?.photoURL || "",
          content: newComment,
          date: Timestamp.now(),
          read: false,
        });
      });

      sendCommentEmail(
        newComment,
        event?.attendees || [],
        event?.title || "",
        eventId
      );
    }
  };

  // TODO: move to utils?
  const saveCalendarEventToGoogle = async (
    eventId: string,
    fieldToUpdate: string,
    newFieldValue: string
  ) => {
    if (!auth.currentUser) {
      console.error("No user found");
      return;
    }

    // Fetch accessToken from user collection in Firestore
    const userDoc = doc(db, "users", auth.currentUser.uid);
    const userSnapshot = await getDoc(userDoc);
    const userData = userSnapshot.data();
    const accessToken = userData?.accessToken;

    if (!accessToken) {
      console.error("No Google access token found for user");
      return;
    }

    try {
      await axios.patch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
        {
          [fieldToUpdate]: newFieldValue,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // TODO: add toast that says "saved"?
    } catch (error) {
      console.error("Error updating Google Calendar event", error);
    }
  };

  const handleAgendaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!event || !eventId) return;
    const eventDoc = doc(db, "events", eventId);

    try {
      const updatedEvent = { ...event, description: e.target.value };
      setEvent(updatedEvent);

      updateDoc(eventDoc, {
        description: e.target.value,
      });

      if (calendarTimeoutRef.current) {
        clearTimeout(calendarTimeoutRef.current);
      }

      calendarTimeoutRef.current = setTimeout(() => {
        // Update Google Calendar event
        saveCalendarEventToGoogle(eventId, "description", e.target.value);
      }, 2000);
    } catch (error) {
      console.error("Error updating event description", error);
    }
  };

  if (!event) {
    return (
      <div className="p-10 bg-gray-100 min-h-screen">Loading event...</div>
    );
  }

  const attendeeStatuses = [
    { title: "Gladly", status: "accepted" },
    { title: "Depends", status: "tentative" },
    { title: "Not right now", status: "declined" },
    { title: "Invited", status: "needsAction" },
  ];

  const isSoon =
    differenceInMinutes(event.start.toDate(), new Date()) < 20 &&
    differenceInMinutes(event.start.toDate(), new Date()) > -90;

  // Format display of event duration (e.g. 1h, 30m, 2d, or "All Day")
  const duration = formatShortDistance(
    event.end.toDate(),
    event.start.toDate()
  );
  const isDaysOrMore = /^\d+d$/.test(duration) && parseInt(duration) >= 2;

  return (
    <div className="flex">
      <div className="w-[72%] p-10 bg-gray-100 min-h-screen flex justify-center">
        {/* Main Pane */}
        <div className="max-w-4xl w-full">
          <div>
            <h1 className="text-2xl font-bold inline-block  mb-1 mt-2">
              {event.title}
            </h1>
            <p className="text-gray-900">
              {duration !== "All day" && !isDaysOrMore ? (
                <>
                  {format(event.start.toDate(), "EEEE, MMMM dd @ hh:mmaaa")} •{" "}
                  {duration}
                </>
              ) : (
                <>{format(event.start.toDate(), "EEEE, MMMM dd")} • All Day</>
              )}
            </p>
            <p>
              <Linkify>{event.location}</Linkify>
            </p>
            {isSoon && event.onlineLocation && (
              <p>
                <button
                  className="bg-blue-600 p-3 py-2 text-white hover:bg-blue-500 shadow-sm rounded-md mt-2"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    window.open(event.onlineLocation, "_blank")
                  }
                >
                  Join Google Meet
                </button>
              </p>
            )}
            {eventCancelled && (
              <p className="mt-4 font-bold border-2 border-dashed border-red-200 p-4 bg-white rounded-md">
                <XCircleIcon className="w-6 h-6 text-red-500 mr-1 inline align-top"></XCircleIcon>{" "}
                This event has been cancelled.
              </p>
            )}
          </div>
          <div>
            <h2 className="font-semibold text-gray-800 mt-6 mb-2">Purpose</h2>
            <textarea
              className="w-full border border-gray-300 rounded-lg p-2 h-24 min-h-24"
              value={event.description}
              onChange={handleAgendaChange}
            />
          </div>
          <div className="mt-6">
            <ChecklistWrapper
              saveChecklistTimeoutRef={saveChecklistTimeoutRef}
              event={event}
              type="agenda"
              setEvent={setEvent}
            />
          </div>
          <div className="mt-8">
            <ChecklistWrapper
              saveChecklistTimeoutRef={saveChecklistTimeoutRef}
              event={event}
              type="task"
              setEvent={setEvent}
            />
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-gray-800">Comments</h2>
            <div className="mt-2">
              <div className="relative">
                <TextareaAutosize
                  className="w-full h-15 border border-gray-300 rounded-lg p-3 pr-28"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Share a comment or add meeting notes..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                      e.preventDefault();
                      handleAddComment();
                    }
                  }}
                />
                <button
                  className="absolute right-3 bottom-4 bg-purple-600 text-white px-4 py-1 rounded text-sm"
                  onClick={handleAddComment}
                >
                  Send
                </button>
              </div>
            </div>
            {event.comments.map((comment) => (
              <Comment comment={comment} key={comment.id} />
            ))}
          </div>
        </div>
      </div>
      <div className="w-[28%] bg-gray-200 p-8">
        <div className="mb-8">
          <div className="flex">
            <button
              className="bg-purple-600 text-white px-3 py-2 mr-2 rounded"
              onClick={handleResolveEarly}
            >
              <CheckIcon className="inline-block h-5 w-5 align-text-top"></CheckIcon>{" "}
              Mark as Done
            </button>
            {
              <button className="bg-blue-500 text-white px-3 py-2 rounded">
                Push
              </button>
            }
          </div>
        </div>
        <div>
          {!userHasRSVPd && (
            <div className="mb-8">
              <h2 className="font-semibold text-gray-600 mb-4">Attend?</h2>
              {attendeeStatuses
                .filter((status) => status.title !== "Invited")
                .map((status) => (
                  <button
                    className="bg-white text-black shadow-sm px-4 py-2 mt-2 rounded block w-full hover:shadow-md active:shadow-none"
                    onClick={(e: any) => handleRSVP(status.status)}
                  >
                    {status.title}
                  </button>
                ))}
            </div>
          )}
          {
            // Filter out the available attendeeStatuses and only show the ones that have attendees
            attendeeStatuses
              .filter((status) =>
                event.attendees.some(
                  (attendee) => attendee.responseStatus === status.status
                )
              )
              .map((status) => (
                <div key={status.status}>
                  <h2 className="font-semibold text-gray-600 mb-4">
                    {status.title}
                  </h2>
                  {event.attendees
                    .filter(
                      (attendee) => attendee.responseStatus === status.status
                    )
                    .map((attendee) => (
                      <div>
                        <div
                          className="bg-white inline-block p-3 rounded-lg shadow-md mb-4 pr-4"
                          key={attendee.email}
                        >
                          <div className="flex items-center">
                            <img
                              src={displayPhoto(attendee.photo)}
                              className="attendee-profile-pic w-10 h-10 rounded-full mr-3"
                              alt={attendee.email}
                            />
                            <span className="truncate text-gray-700">
                              {attendee.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))
          }
        </div>

        <a
          href={event.googleCalendarLink}
          target="_blank"
          rel="noreferrer"
          className="text-gray-500 mt-4 block"
        >
          Open in Google Calendar
        </a>
      </div>
    </div>
  );
};

export default EventDetail;
