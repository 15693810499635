import React, { useEffect, useState } from "react";
import {
  Timestamp,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { endOfDay } from "date-fns";

import EventProps from "../interfaces/EventProps";
import EventCard from "./EventCard";

const Today: React.FC = () => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchEventsFromFirestore = async (
    userId: string,
    userEmail: string
  ) => {
    const q = query(
      collection(db, "events"),
      where("userIds", "array-contains", userId),
      where(
        "start",
        ">=",
        Timestamp.fromDate(new Date(Date.now() - 3600 * 1000))
      ),
      where("start", "<=", Timestamp.fromDate(endOfDay(new Date()))),
      where("status", "!=", "cancelled"),
      orderBy("start"),
      limit(10)
    );

    const querySnapshot = await getDocs(q);
    const eventsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title,
      description: doc.data().description,
      location: doc.data().location,
      onlineLocation: doc.data().onlineLocation,
      start: doc.data().start,
      end: doc.data().end,
      organizer: doc.data().organizer,
      attendees: doc.data().attendees,
      attendeeData: doc.data().attendeeData,
      comments: doc.data().comments,
      status: doc.data().status || "",
    }));

    // Only show events I haven't declined, or I'm the organizer
    const filteredEvents = eventsData.filter((event) => {
      const isOrganizer =
        event.organizer === userEmail &&
        (event.attendees.length === 0 || !event.attendees);
      const isAttendee = event.attendees.some(
        (attendee: any) =>
          attendee.email === userEmail && attendee.responseStatus !== "declined"
      );
      return isOrganizer || isAttendee;
    });

    setEvents(filteredEvents);
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchEventsFromFirestore(user.uid, user.email!);
      } else {
        navigate("/signin");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) return <div>Loading...</div>;

  if (events.length === 0)
    return (
      <p className="text-gray-800 items-center justify-center flex flex-col mt-16">
        All done for today!
      </p>
    );

  return (
    <div>
      <p className="text-gray-500 items-center flex flex-col mb-8 mt-20">
        Here's what's up today.
      </p>
      <ul className="space-y-6 items-center flex flex-col">
        {events.map((event) => (
          <EventCard event={event} key={event.id}></EventCard>
        ))}
      </ul>
    </div>
  );
};

export default Today;
