// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWAxQP4C-6MYaLOVFGcDW_Iv7RtmntQOo",
  authDomain: "paceful-558b9.firebaseapp.com",
  projectId: "paceful-558b9",
  storageBucket: "paceful-558b9.appspot.com",
  messagingSenderId: "776182286025",
  appId: "1:776182286025:web:5271ca63f5bd80506c6f18",
  measurementId: "G-L0ZPY7EP9G",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const provider = new GoogleAuthProvider();

// Add scope for reading and writing the calendar, and reading contacts
provider.addScope("https://www.googleapis.com/auth/calendar");
provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
provider.addScope(
  "https://www.googleapis.com/auth/admin.directory.user.readonly"
);
provider.setCustomParameters({
  'access_type': 'offline',
  'prompt': 'consent'
});

const signIn = async () => {
  try {
    await signInWithPopup(auth, provider);
  } catch (rawError) {
    const error = rawError as any;
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData?.email;
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(errorCode, errorMessage, email, credential);
  }
};

// Logout
const signOut = () => {
  auth.signOut();
};

// Export the services
export { auth, db, firebaseApp, provider, signIn, signOut };
