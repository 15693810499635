// src/components/Welcome.tsx
import React from "react";
import { signOut } from "../firebase";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface SidebarProps {
  unreadNotifs: boolean;
  selectedNav: string;
}

const Sidebar: React.FC<SidebarProps> = ({ unreadNotifs, selectedNav }) => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      signOut();
      // Refresh page
      window.location.reload();
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { tagName } = event.target as HTMLElement;
      if (tagName !== "INPUT" && tagName !== "TEXTAREA") {
        if (event.key === "t") {
          navigate("/today");
        } else if (event.key === "i") {
          navigate("/inbox");
        } else if (event.key === "w") {
          navigate("/this-week");
        } else if (event.key === "n") {
          navigate("/next-week");
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <div className="bg-gray-900 w-44 fixed h-screen">
      <div className="mx-6 mt-12 text-center">
        <img
          src="/images/tentacles.png"
          alt="Tentacles"
          width="61"
          height="62"
          className="mx-auto"
        />
      </div>
      <div className="mx-7 mt-12 text-center">
        <div className="relative mb-12">
          <NavLink to="/inbox" className="nav-link" title="Shortcut: I">
            Inbox
            {unreadNotifs && (
              <div className="absolute top-[17px] left-[85px] rounded-full h-2 w-2 bg-purple-500"></div>
            )}
          </NavLink>
        </div>
        <NavLink to="/today" className="nav-link" title="Shortcut: T">
          Today
        </NavLink>
        <NavLink
          to="/this-week"
          className={`nav-link ${selectedNav === "this-week" ? "active" : ""}`}
          title="Shortcut: W"
        >
          This week
        </NavLink>
        <NavLink
          to="/next-week"
          title="Shortcut: N"
          className={`nav-link ${selectedNav === "next-week" ? "active" : ""}`}
        >
          Next week
        </NavLink>
      </div>
      <div className="flex flex-col justify-center absolute bottom-6 left-10">
        <button
          onClick={handleSignOut}
          className="border border-purple-600 text-white px-4 py-2 rounded-md shadow-md"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
